<template>
    <div>
        <vx-input-group class="mb-4 text-dark">
            <vs-input v-model="shareHash"/>
            <template slot="prepend">
            <div class="prepend-text bg-grey text-white narrow-prepend ">
                <span>{{$t('shareCode')}}</span>
            </div>
            </template>
        </vx-input-group>
        <vx-input-group class="mb-4 text-dark">
            <vs-input v-model="shareLink"/>
            <template slot="prepend">
            <div class="prepend-text bg-grey text-white narrow-prepend ">
                <span>{{$t('shareLink')}}</span>
            </div>
            </template>
            <template slot="append">
            <div class="append-text btn-addon">
                <vs-button class="bg-dark" @click="copyShareLink">{{$t('Copy')}}</vs-button>
            </div>
            </template>
        </vx-input-group>
        <vx-input-group class="mb-4 text-dark qr-zone">
            <qrcode-vue ref="qr" :value="shareLink" size="1000" class="qr-code bg-white"></qrcode-vue>
            <template slot="prepend">
            <div class="prepend-text bg-grey text-white narrow-prepend ">
                <span>{{$t('qrCode')}}</span>
            </div>
            </template>                
            <template slot="append">
            <div class="append-text btn-addon">
                <vs-button class="bg-dark" @click="downloadQrCode"><feather-icon icon="DownloadIcon" svgClasses="h-8 w-8" class="p-2" /></vs-button>
            </div>
            </template>
        </vx-input-group>
    </div>

</template>

<script>

import QrcodeVue from 'qrcode.vue'

export default {
  name: 'share-badge',
  props: {
    shareHash: {
      type: String
    }
  },
  components: { 
    QrcodeVue
  },
  data () {
    return {
      shareLinkBaseUrl: `${this.$appConfig.appBaseUrl}/s/`
    }
  },
  methods: {
    // ROYBON / Téléchargement du QR Code
    downloadQrCode () {
      const canvas = this.$refs.qr.$refs['qrcode-vue']
      const filename = `${this.shareHash}.png`
      canvas.toBlob(function (blob) {
        const url = window.URL.createObjectURL(blob)
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', filename)
        document.body.appendChild(link)
        link.click()
      })
    },

    // ROYBON / Copie du lien dans le presse papier
    copyShareLink () {
      const thisIns = this
      this.$copyText(this.shareLink).then(function () {
        thisIns.$vs.notify({
          title: thisIns.$t('success'),
          text: thisIns.$t('TextCopySuccess'),
          color: 'success',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-check-circle'
        })
      }, function () {
        thisIns.$vs.notify({
          title: thisIns.$t('error'),
          text: thisIns.$t('TextCopyError'),
          color: 'danger',
          iconPack: 'feather',
          position: 'top-center',
          icon: 'icon-alert-circle'
        })
      })
    }

  },
  computed: {
    shareLink () { 
      return `${this.shareLinkBaseUrl}${this.shareHash}`
    }
  },

  beforeMount () {
    
  },

  created () {
  },
  mounted () {
  },

  watch: {
  }
}
</script>
<style lang="scss" scoped>
  .project_title {
    display: inline-table;
  }
</style>
