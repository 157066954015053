<template>
  <div id="shares-page">
      
    <vs-alert v-if="!this.can_see_page" color="warning" :title="$t('AccessDeniedTitle')" active="true">
      <p>{{ $t(this.AccessDeniedMessage) }}<br>[{{ this.AccessDeniedInfos }}]</p>
    </vs-alert>

    <template v-else>
      <div class="tab-text mt-8">
        <!-- SHARE CREATION -->
        <vx-card :title="$t('shareCreation')">

          <!-- share welcome message -->
          <template v-if="!shareCreationStarted">
            <div class="px-4">
              <p>{{$t('shareWelcomeMessage')}}</p>
              <vs-button class="mt-2" @click="shareCreationStarted = true">{{$t('shareStartButton')}}</vs-button>
            </div>
          </template>

          <!-- new share creation form -->
          <template v-if="shareCreationStarted && !shareLinkCreated">    
            <form-wizard color="rgba(var(--vs-primary), 1)" 
              :title="null" :subtitle="null" 
              :finishButtonText="$t('Finish')" :nextButtonText="$t('Next')" :backButtonText="$t('Back')" 
              @on-complete="createShareAction"
              >
              <tab-content :title="$t('Projects')" class="mb-5" icon="feather icon-box" :before-change="validateStep1">
                <h3>{{$t('projectsChoice')}}</h3>
                <!-- projects choice -->
                <vs-table class="shareable_projects w-full" multiple v-model="projectsSelected" pagination max-items="5" search :data="userShareableProjects">
                  <template slot="thead">
                    <vs-th sort-key="project_nicename">{{$t('Project')}}</vs-th>
                  </template>
                  <template slot-scope="{data}">
                  <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
                    <vs-td :data="data[indextr].project_nicename">
                    {{ decodeURIComponent(data[indextr].project_nicename) }}
                    </vs-td>
                  </vs-tr>
                  </template>
                </vs-table>
              </tab-content>

              <tab-content :title="$t('Capabilities')" class="mb-5" icon="feather icon-toggle-right">
                <h3>{{$t('projectsCaps')}}</h3>

                <vx-card v-for="(project, i) in projectsSelected" :key="project.project_slug" :title="project.project_nicename" class="my-8 p-4"
                  :card-background="`linear-gradient(140deg, rgba(255,255,255,.95) 75%, rgba(255,255,255,0.75) 85%, rgba(255,255,255,.6)), url({{this.$appConfig.imgBaseUrl}}/project/cover/${project.project_id}/thumb_2000_600.jpg)`"
                  >
                  <vs-alert class="mx-4 mb-2">{{$t('thisProjectWillBeVisibleInList')}}</vs-alert>
                  <div class="flex">
                    <div v-for="dateCap in projectCapsDates" :key="project.project_slug+'_'+dateCap" class="mt-1 flex-1">
                      <div class="flex w-full mb-8">
                        <label class="w-1/4 mt-2">{{$t(dateCap)}}</label>
                        <datepicker :disabledDates="disabledDates" :inline="true" :language="languages[language]" format="yyyy-MMMM-dd" v-model="project[dateCap]" class="w-3/4 pr-12"></datepicker>
                      </div>
                    </div>
                  </div>
                  <div class="flex">
                    <div class="w-1/3">
                      <div v-for="basicCap in projectCapsBasicCheckboxes" :key="project.project_slug+'_'+basicCap" class="mt-1 flex">
                        <vs-switch class="ml-5" v-model="projectsSelected[i][basicCap]" />
                        <label class="ml-5">{{$t(basicCap)}}</label>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div v-for="warningCap in projectCapsWarningCheckboxes" :key="project.project_slug+'_'+warningCap" class="mt-1 flex">
                        <vs-switch class="ml-5" v-model="projectsSelected[i][warningCap]" color="warning" icon-pack="feather" vs-icon="icon-alert-circle"/>
                        <label class="ml-5">{{$t(warningCap)}}</label>
                      </div>
                    </div>
                    <div class="w-1/3">
                      <div v-for="adminCap in projectCapsAdminCheckboxes" :key="project.project_slug+'_'+adminCap" class="mt-1 flex">
                        <vs-switch class="ml-5" v-model="projectsSelected[i][adminCap]" color="danger" icon-pack="feather" vs-icon="icon-alert-triangle"/>
                        <label class="ml-5">{{$t(adminCap)}}</label>
                      </div>
                    </div>
                  </div>
                </vx-card>

              </tab-content>

              <tab-content :title="$t('Limitations')" class="p-4" icon="feather icon-shield">
                <h3>{{$t('Limitations')}}</h3>
                <p class="mt-4 flex"><feather-icon icon="InfoIcon" svgClasses="mx-4 h-6 w-6 stroke-current text-grey" class="block" />{{$t('limitationsExplaination')}}</p>
                <template>
                  <vx-input-group class="mx-4 mt-6 w-full">
                    <template slot="prepend">
                      <div class="prepend-text text-dark vs-inputx vs-input--input large-prepend ">
                        <vs-switch  class="mr-5" v-model="shareLimitUsageNumberActive" color="dark" icon-pack="feather" vs-icon-on="icon-activity"/>
                        <span>{{$t('shareLimitUsageNumber')}}</span>
                      </div>
                    </template>
                    <vs-input-number v-if="shareLimitUsageNumberActive" min="1" max="1000" v-model="shareLimitUsageNumber" :disabled="!shareLimitUsageNumberActive" color="dark"/>
                  </vx-input-group>
                  <vs-alert class="mx-4" :active="shareLimitUsageNumberActive">{{$t('shareLimitUsageNumberExplainations')}}</vs-alert>
                  <vx-input-group class="mx-4 mt-6 w-full">
                    <template slot="prepend">
                      <div class="prepend-text text-dark vs-inputx vs-input--input large-prepend ">
                        <vs-switch class="mr-5" v-model="shareLimitPasswordActive" color="dark" icon-pack="feather" vs-icon-on="icon-lock"/>
                        <span>{{$t('passwordProtection')}}</span>
                      </div>
                    </template>
                    <vs-input v-if="shareLimitPasswordActive" v-model="shareLimitPassword" :disabled="!shareLimitPasswordActive" color="dark" />
                  </vx-input-group>
                  <vs-alert class="mx-4" :active="shareLimitPasswordActive">{{$t('shareLimitPasswordExplainations')}}</vs-alert>
                  <vx-input-group class="mx-4 mt-6 w-full">
                    <template slot="prepend">
                      <div class="prepend-text text-dark vs-inputx vs-input--input large-prepend ">
                        <vs-switch class="mr-5" v-model="shareExpireDateActive" color="dark" icon-pack="feather" vs-icon-on="icon-clock"/>
                        <span>{{$t('expireDate')}}</span>
                      </div>
                    </template>
                    <datepicker v-if="shareExpireDateActive" class="w-full" v-model="shareExpireDate" format="yyyy-MM-dd" :disabled="!shareExpireDateActive" color="dark"></datepicker>
                  </vx-input-group>
                  <vs-alert class="mx-4" :active="shareExpireDateActive">{{$t('shareLimitDateExplainations')}}</vs-alert>
                </template>
              </tab-content>

              <tab-content :title="$t('Validation')" class="mb-5" icon="feather icon-check">
                <div class="m-20">
                  <h3>{{$t('shareCreationReady')}} !</h3>
                  <p class="mt-4">{{$t('shareCreationClickToConfirm')}}.</p>
                </div>
              </tab-content>

            </form-wizard>
          </template>

          <!-- share final diffusion -->
          <template v-if="shareCreationStarted && shareLinkCreated">
            <vx-card :title="$t('shareCodeZone')" 
            title-color="#fff"
            content-color="#fff"
            card-background="linear-gradient(to right, #708e1b, #8bb516)">
              <p>{{$t('shareCodeZoneExplainations')}}</p>
              <share-badge class="mt-4" :shareHash="shareHash"/>

            </vx-card>
            <vs-row class="mt-8">
              <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
                <vs-button type="line" class="flex vs-align-center" @click="createNewShare">{{$t('createNewShare')}}</vs-button>
              </vs-col>
            </vs-row>
          </template>

          <!-- cancel button -->
          <vs-row v-if="shareCreationStarted">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <vs-button type="line" color="danger" class="flex vs-align-center" @click="shareCreationStarted = false">{{$t('Cancel')}}</vs-button>
            </vs-col>
          </vs-row>
        </vx-card>

        <!-- PREVIOUS SHARES LIST -->
        <div v-if="!shareCreationStarted" class="mt-8">
          <h3>{{$t('previousShares')}}</h3>
          <vs-input icon-no-border :placeholder="$t('Search')" v-model="searchQuery" class="w-full input-rounded-full mt-8" icon="icon-search" icon-pack="feather" />
          <vs-progress v-if="!sharesLoaded" indeterminate ></vs-progress>
          <vs-alert v-if="sharesLoaded && userPortalShares == null" color="warning" class="mt-8">{{this.$t('noShares')}}</vs-alert>
          <template v-else>
            <div v-for="share in userPortalShares" :key="share.hash">
                <share 
                v-if="
                sanitizeQuery(share.first_name).includes(searchQuerySanitized)
                || sanitizeQuery(share.last_name).includes(searchQuerySanitized)
                || sanitizeQuery(share.caps).includes(searchQuerySanitized)
                || sanitizeQuery(share.hash).includes(searchQuerySanitized)
                "
                :data="share" @shareDeleted="getShares" :capsCollapsed="capsCollapsed" :shareCollapsed="shareCollapsed"/>
            </div>
          </template>
          
        </div>
      </div>
    </template>

  </div>
</template>
<script>
import axios from '@/axios.js'
import moment from 'moment' // for date comparaison
import logActivity from '@/logActivity.js'
import vSelect from 'vue-select'
import CryptoJS from 'crypto-js'
import sha1 from 'crypto-js/sha1'
import {FormWizard, TabContent} from 'vue-form-wizard'
import 'vue-form-wizard/dist/vue-form-wizard.min.css'
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'
import i18n from '@/i18n/i18n.js'
import Share from './share/share.vue'
import shareBadge from './share/shareBadge.vue'

export default {
  components: {
    'v-select': vSelect,
    FormWizard,
    TabContent,
    Datepicker,
    Share, 
    shareBadge
  },
  data () {
    return {
      
      language: i18n.locale,
      languages: lang,

      disabledDates: {
        to: new Date()
      },
      capsCollapsed: true,
      shareCollapsed: false,
      searchQuery: '',

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      getUserShareableProjects: 'getUserShareableProjects',
      createShare: 'createShare',
      getUserPortalShares: 'getUserPortalShares',
      getPortalShares: 'getPortalShares',

      // ROYBON / Interprétation des droits
      can_see_page: null,
      AccessDeniedMessage: '',
      AccessDeniedInfos: '',

      // ROYBON / Listes des projets partageables
      userShareableProjects : [],
      projectsSelected: [],

      // ROYBON / Liste des capacités
      projectCapsBasicCheckboxes: [
        'can_project_model_see',
        'can_project_tags_see',
        'can_project_tools_use',
        'can_project_users_see'
      ],
      projectCapsWarningCheckboxes: [
        'can_project_tags_add',
        'can_project_shares_send'
      ],
      projectCapsAdminCheckboxes: [
        'can_project_customize',
        'can_project_tags_manage',
        'can_project_users_manage',
        'can_project_layers_manage'
      ],
      projectCapsDates: [
        'can_project_access_start_date',
        'can_project_access_end_date'
      ],

      // ROYBON / Share limitations
      shareLimitUsageNumberActive: false,
      shareLimitUsageNumber: 1,
      shareLimitPasswordActive: false,
      shareLimitPassword: '',
      shareExpireDateActive: false,
      shareExpireDate: '',

      // ROYBON / Share link
      shareCreationStarted: false,
      shareLinkCreated: false,
      shareLinkBaseUrl: `${this.$store.state.viewerBaseUrl}/s/`,

      // ROYBON / Previous shares
      userPortalShares: null,
      sharesLoaded: false

    }
  },
  methods: {    
    // ROYBON / Obtient les infos sur les partages accessibles à l'utilisateur
    getShares () {
      if (this.currentuserIsAdmin) {
        this.getAllShares()
      } else {
        this.getUserShares()
      }
    },
    getUserShares () {
      const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.$store.state.AppActiveUser.user_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserPortalShares}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') this.userPortalShares = response.data
          this.sharesLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    getAllShares () {
      const params = `portal_id=${this.$store.state.portal_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/${this.getPortalShares}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response != null && typeof response.data != 'undefined' && response.data != null && response.data != 'null') this.userPortalShares = response.data
          this.sharesLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Recommence le formulaire
    createNewShare () {
      this.shareLinkCreated = false
    },

    // ROYBON / Action à la fin du formulaire
    createShareAction () {
      /*
      let params = `portal_id=${this.$store.state.portal_id}&user_id=${this.$store.state.AppActiveUser.user_id}`
      if (this.shareLimitUsageNumberActive) params = `${params}&use_limit=${this.shareLimitUsageNumber}`
      if (this.shareLimitPasswordActive && this.shareLimitPassword.length > 0) params = `${params}&password=${encodeURIComponent(sha1(this.shareLimitPassword))}`
      if (this.shareExpireDateActive && this.shareExpireDate !== '' && this.shareExpireDate !== null) params = `${params}&expire_date=${encodeURIComponent(this.shareExpireDate.toISOString().slice(0, 10))}`
      params = `${params}&caps=${encodeURIComponent(JSON.stringify(this.projectsSelected))}`
      params = `${params}&hash=${this.shareHash}`
      */
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('user_id', `${this.$store.state.AppActiveUser.user_id}`)
      if (this.shareLimitUsageNumberActive) {
        params.set('use_limit', `${this.shareLimitUsageNumber}`)
      }
      if (this.shareLimitPasswordActive && this.shareLimitPassword.length > 0) {
        params.set('password', `${encodeURIComponent(sha1(this.shareLimitPassword))}`)
      }
      if (this.shareExpireDateActive && this.shareExpireDate !== '' && this.shareExpireDate !== null) {
        params.set('expire_date', `${encodeURIComponent(this.shareExpireDate.toISOString().slice(0, 10))}`)
      }
      params.set('caps', `${encodeURIComponent(JSON.stringify(this.projectsSelected))}`)
      params.set('hash', `${this.shareHash}`)

      const rimnat_api_call_url = `${this.api_server_baseurl}/share/${this.createShare}`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.shareLinkCreated = true
            this.$vs.notify({
              color:'success',
              title:this.$t('shareCreationSuccess'),
              text:this.$t('shareCreationSuccessText')
            })
            // log l'activité du user
            logActivity.add('share_creation', this.shareHash)
            this.getShares()
          } else {
            // error
            event.target.checked = !event.target.checked
            this.$vs.notify({
              color:'danger',
              title:this.$t('shareCreationError')
            })
          }
        })
        .catch((error)   => { 
          console.log(error) 
          // error
          event.target.checked = !event.target.checked
          this.$vs.notify({
            color:'danger',
            title:this.$t('shareCreationError')
          })
        })
     
    },
    // ROYBON / Contrôle du formulaire
    validateStep1 () {
      return new Promise((resolve, reject) => {
        if (this.projectsSelected.length > 0) {
          resolve(true)
        } else {
          reject('correct all values')
        }
      })
    },
    // ROYBON / Liste les projets pour lesquels le current user peut faire des partages
    buildUserShareableProjectsList () {
      const params = `portal_id=${this.$store.state.portal_id}&user_id=${this.$store.state.AppActiveUser.user_id}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/user/${this.getUserShareableProjects}?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response != null && typeof response.data != 'undefined' && response.data != null && response.data != 'null') {
            this.userShareableProjects = response.data
          }
        })
        .catch((error)   => { console.log(error) })
    },

    // ROYBON / Check si l'affichage de la page est autorisé
    canSeePage () {
      const gc =                this.$store.state.current_user_global_caps
      const poc =               this.$store.state.current_user_portal_caps
      const poc_start_date =    poc.can_portal_access_start_date
      const poc_end_date =      poc.can_portal_access_end_date
      if (gc.can_global_access === '1' && poc.can_portal_access === '1') {
        if (poc_start_date !== null && moment().isSameOrBefore(poc_start_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessNotYetOpened'
          this.AccessDeniedInfos = poc_start_date
        } else if (poc_end_date !== null && moment().subtract(1, 'd').isSameOrAfter(poc_end_date)) {
          this.can_see_page = false
          this.AccessDeniedMessage = 'PortalAccessExpired'
          this.AccessDeniedInfos = poc_end_date
        } else {
          this.can_see_page = true
        }
      } else {
        this.can_see_page = false
        this.AccessDeniedMessage = 'PortalAccessDeniedFull' 
        this.AccessDeniedInfos = 'global or portal access invalid'
      }
    }, 

    // ROYBON / Sanitize query
    sanitizeQuery (string) {
      if (string != null && string.length > 0) {
        return string.toLowerCase().trim()
      } else {
        return '' 
      }
    }
  },

  computed: {
    shareHash () { 
      const date = sha1(new Date().toISOString().slice(0, 10)).toString(CryptoJS.enc.sha1).slice(0, 4)
      const identifier = sha1(`p${this.$store.state.portal_id}u${this.$store.state.AppActiveUser.user_id}`).toString(CryptoJS.enc.sha1).slice(0, 4)
      const projects = sha1(`${JSON.stringify(this.projectsSelected)}`).toString(CryptoJS.enc.sha1).slice(0, 4)
      const random = sha1(`${Math.random()}`).toString(CryptoJS.enc.sha1).slice(0, 4)
      return `${date}-${identifier}-${projects}-${random}`
    },
    shareLink () { 
      return `${this.shareLinkBaseUrl}${this.shareHash}`
    },
    currentuserIsAdmin () {
      return this.$store.state.current_user_portal_caps.can_portal_manage === '1' || this.$store.state.current_user_portal_caps.can_portal_projects_manage === '1'
    }, 
    searchQuerySanitized () {
      return this.searchQuery.toLowerCase().trim()
    }
  },

  beforeMount () {
    this.canSeePage()
  },

  created () {
    this.buildUserShareableProjectsList()
  },
  mounted () {
    this.isMounted = true
    if (this.can_see_page) {
      this.getShares()
    }
  },

  watch: {
    can_see_page: function () {
      if (this.can_see_page) {
        logActivity.add('share_manager_access', 'success')
      } else {
        logActivity.add('share_manager_access_refused', 'error')
      }
    },
    searchQuery: function () {
    }
  }
}

</script>
<style lang="scss">

.shareable_projects .con-input-search{
    margin-left: 0;
}   
.narrow-prepend {
  width: 170px;
}
.large-prepend {
  width: 400px;
}   
.qr-zone {
  text-align: center;
}

.qr-code > canvas {
  max-height: 100px;
  max-width: 100px;
  margin-top: 8px;
  margin-bottom: 2px;
}
</style>
