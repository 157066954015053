<template>
    <div class="grid-layout-container alignment-block">
      <p v-if="!shareUsersLoaded">{{$t('Loading')}}...</p>
      <template v-else>
        <template>
          <vx-card 
          :title="shortDate(shareInfos.sql_timestamp)+separator+capitalize(shareUser.first_name)+' '+capitalize(shareUser.last_name)+separator+(shareInfos.caps.length+' '+(shareInfos.caps.length > 1 ? $t('Projects') : $t('Project'))+separator+shareInfos.uses+' '+(shareInfos.uses > 1 ? $t('Users') : $t('User'))+separator+(shareIsValid ? $t('usable') : $t('locked'))).toLowerCase()"
          :card-background="cardBackgroundColor"
          :title-color="cardTitleColor"
          :collapsed="shareCollapsed"
          class="my-8 p-4" :collapse-action="true"
          >
          
            <vs-tabs ref="tabs" alignment="fixed" position="left" :color="cardTitleColor">

              <!----------------------- INFOS TAB --------------------->
              <vs-tab :label="$t('shareDetails')" icon-pack="feather" icon="icon-info">
                <div class="m-4 w-full">
                  <vs-row vs-type="flex" vs-w="12" class="mx-4">
                    <!-- creator -->
                    <vs-col vs-justify="center" vs-align="center" vs-w="4">
                      <h6>{{$t('createdBy')}}</h6>
                      <div class="flex">
                        <user-avatar :key="this.shareUser.last_name" :user="this.shareUser" size="large" />
                        <div>
                          <p>{{ shareUser.first_name + ' ' + shareUser.last_name | capitalize }}</p>
                          <p>{{ shareInfos.sql_timestamp | longDate }}</p>
                        </div>
                      </div>
                    </vs-col>
                    <!-- limitations -->
                    <vs-col vs-justify="center" vs-align="center" vs-w="4">
                      <h6>{{$t('Limitations')}}</h6>
                      <div class="flex flex-wrap">
                        <div class="w-full my-2">
                          <vs-chip v-if="shareLimitUsageNumberActive" color="warning">{{$t('shareLimitUsageNumber')}} : {{shareInfos.use_limit}}</vs-chip>
                          <vs-chip v-if="shareLimitPasswordActive" color="warning">{{$t('passwordProtection')}}</vs-chip>
                          <vs-chip v-if="shareExpireDateActive" color="warning">{{$t('expireDate')}} : {{shareInfos.expire_date | shortDate}}</vs-chip>
                          <vs-chip v-if="!shareLimitUsageNumberActive && !shareLimitPasswordActive && !shareExpireDateActive">{{ $t('noLimitations') }}</vs-chip>
                        </div>
                      </div>
                    </vs-col>
                    <!-- state -->
                    <vs-col vs-justify="center" vs-align="center" vs-w="4">
                      <h6>{{$t('Validity')}}</h6>
                      <div class="flex">
                        <feather-icon :icon="shareIcon" :svgClasses="`w-12 h-12 text-${cardTitleColor}`" />
                        <template v-if="!shareIsValid">
                          <vs-alert color="danger" v-if="shareLimitUsageExceeded">{{$t('shareLimitUsageExceeded')}}</vs-alert>
                          <vs-alert color="danger" v-if="shareExpireDateExceeded">{{$t('shareExpireDateExceeded')}}</vs-alert>
                        </template>
                      </div>
                    </vs-col>
                  </vs-row>
                  <vs-divider/>
                  <div class="px-4 w-full">
                    <h5>{{$t('projectsCaps')}}</h5>
                    <div v-for="pr in shareInfos.caps" :key="pr.project_id" class="w-full" >             
                      <!-- caps -->
                      <vx-card :key="pr.project_slug" :title="decodeURIComponent(pr.project_nicename)" class="px-4 my-4" :collapse-action="true" :collapsed="capsCollapsed"
                        :card-background="`linear-gradient(140deg, rgba(255,255,255,.95) 75%, rgba(255,255,255,0.75) 85%, rgba(255,255,255,.6)), url({{this.$appConfig.imgBaseUrl}}/project/cover/${pr.project_id}/thumb_2000_300.jpg)`">
                        <div class="flex">
                          <div v-for="dateCap in projectCapsDates" :key="pr.project_slug+'_'+dateCap" class="mt-1 flex-1">
                            <div class="flex w-full mb-8">
                              <label class="w-1/4 mt-2">{{$t(dateCap)}}</label>
                              <datepicker class="w-3/4 pr-12" disabled v-model="pr[dateCap]" :language="languages[language]"></datepicker>
                            </div>
                          </div>
                        </div>
                        <div class="flex">
                          <div class="w-1/3">
                            <div v-for="basicCap in projectCapsBasicCheckboxes" :key="pr.project_slug+'_'+basicCap" class="mt-1 flex">
                              <vs-switch disabled class="ml-5" v-model="pr[basicCap]" />
                              <label class="ml-5">{{$t(basicCap)}}</label>
                            </div>
                          </div>
                          <div class="w-1/3">
                            <div v-for="warningCap in projectCapsWarningCheckboxes" :key="pr.project_slug+'_'+warningCap" class="mt-1 flex">
                              <vs-switch disabled class="ml-5" v-model="pr[warningCap]" color="warning" icon-pack="feather" vs-icon="icon-alert-circle"/>
                              <label class="ml-5">{{$t(warningCap)}}</label>
                            </div>
                          </div>
                          <div class="w-1/3">
                            <div v-for="adminCap in projectCapsAdminCheckboxes" :key="pr.project_slug+'_'+adminCap" class="mt-1 flex">
                              <vs-switch disabled class="ml-5" v-model="pr[adminCap]" color="danger" icon-pack="feather" vs-icon="icon-alert-triangle"/>
                              <label class="ml-5">{{$t(adminCap)}}</label>
                            </div>
                          </div>
                        </div>
                      </vx-card>
                    </div>
                  </div>
                </div>
              </vs-tab>

              <!----------------------- USERS TAB --------------------->
              <vs-tab :label="$t('Users') + ' (' + shareUsers + ')'" icon-pack="feather" icon="icon-users">
                <div class="m-4 w-full">
                  <!-- usage -->
                  <div class="p-4 flex">
                    <template v-if="shareUsersLoaded">
                      <template v-if="shareUsersLoaded && shareUsers > 0">
                        <vs-chip class="m-2" v-for="user in shareUsage" :key="user.sql_id">
                          <user-avatar :key="user.last_name" :user="user" size="small" />
                          {{user.first_name + ' ' + user.last_name | capitalize}}<br>{{user.sql_timestamp | longDate}}
                        </vs-chip>
                      </template>
                      <vs-alert v-else>{{$t('noUsers')}}</vs-alert>
                    </template>
                  </div>
                </div>
              </vs-tab>

              <!----------------------- INFOS TAB --------------------->
              <vs-tab :label="$t('shareInformations')" icon-pack="feather" icon="icon-share-2">
                <vs-alert v-if="!shareIsValid" color="danger">{{$t('shareUnusable')}}</vs-alert>
                <share-badge class="mt-4" :shareHash="shareInfos.hash"/>
              </vs-tab>

            </vs-tabs>


            <template slot="footer">
              <div class="flex mt-4 w-full md:w-1/2 lg:w-1/3 xl:w-1/4 ml-auto">
                <vs-button class="flex-1 ml-auto" type="line" color="danger" icon-pack="feather" icon="icon-trash"
                @click="removeShareConfirm()">
                  {{$t('removeShare')}}
                </vs-button>
              </div>
            </template>

          </vx-card>
        </template>
      </template>
    </div>

</template>

<script>
import moment from 'moment'
import axios from '@/axios'
import logActivity from '@/logActivity.js'
import shareBadge from './shareBadge.vue'
import Datepicker from 'vuejs-datepicker'
import * as lang from 'vuejs-datepicker/src/locale'
import i18n from '@/i18n/i18n.js'

export default {
  name: 'share',
  props: {
    display: {
      type: Boolean
    },
    editable: {
      type: Boolean
    },
    shareCollapsed: {
      type: Boolean,
      default: true
    },
    capsCollapsed: {
      type: Boolean,
      default: true
    },
    data: {
      type: Object,
      default: () => {}
    }
  },

  components: {
    moment, 
    shareBadge,
    Datepicker
  },
  data () {
    return {
      language: i18n.locale,
      languages: lang,

      // ROYBON / API calls
      api_server_baseurl: this.$appConfig.apiBaseUrl,
      deletePortalShare: 'deletePortalShare',
      
      separator: ', ',

      shareLoaded: false,
      shareUser: {
        user_id: null,
        first_name: '',
        last_name: ''
      },
      shareInfos: {
        sql_timestamp: null,
        portal_id: null,
        hash: null,
        expire_date: null,
        caps: {
        },
        use_limit: null,
        uses: null,
        password: null
      },
      
      // ROYBON / Usage du share
      shareUsage: {},
      shareUsersLoaded: false,
      shareUsers: 0,

      // ROYBON / Liste des capacités
      projectCapsBasicCheckboxes: [
        'can_project_model_see',
        'can_project_tags_see',
        'can_project_tools_use',
        'can_project_users_see'
      ],
      projectCapsWarningCheckboxes: [
        'can_project_tags_add',
        'can_project_shares_send'
      ],
      projectCapsAdminCheckboxes: [
        'can_project_customize',
        'can_project_tags_manage',
        'can_project_users_manage',
        'can_project_layers_manage'
      ],
      projectCapsDates: [
        'can_project_access_start_date',
        'can_project_access_end_date'
      ]
    }
  },
  methods: {
    // ROYBON / Demande la confirmation de suppression du partage
    removeShareConfirm () {
      this.$vs.dialog({
        type: 'confirm',
        color: this.shareAlreadyUsed ? 'danger' : 'warning',
        title: this.$t('Confirm'),
        text: this.shareAlreadyUsed ? this.$t('removeShareConfirmWithUsers') : this.$t('removeShareConfirmWithoutUsers'),
        acceptText: this.$t('Confirm'),
        cancelText: this.$t('Cancel'),
        accept: this.shareAlreadyUsed ? this.removeShareActionWithUsers : this.removeShareActionWithoutUsers
      })
    },
    // ROYBON / Action de retrait du share AVEC users
    removeShareActionWithUsers () {
      this.removeShareAction(true)
    },
    // ROYBON / Action de retrait du share SANS users
    removeShareActionWithoutUsers () {
      this.removeShareAction(false)
    },
    // ROYBON / Action de retrait du share
    removeShareAction (delete_users) {
      //let params = `portal_id=${this.$store.state.portal_id}&share_hash=${this.shareInfos.hash}`
      //if (delete_users === true) params = `${params}&delete_users=true`
      const params = new FormData()
      params.set('portal_id', `${this.$store.state.portal_id}`)
      params.set('share_hash', `${this.shareInfos.hash}`)
      if (delete_users === true) {
        params.set('delete_users', 'true')
      }
      const rimnat_api_call_url = `${this.api_server_baseurl}/portal/deletePortalShare`
      axios.post(rimnat_api_call_url, params)
        .then((response) => { 
          if (response.data[0] === 'ok' || response.data == 'ok') {
            // success
            this.$vs.notify({
              color:'success',
              text:this.$t('shareRemovalSuccess')
            })
            logActivity.add('share_deleted', this.shareInfos.hash)
            this.$emit('shareDeleted')
          } else {
            // error
            this.$vs.notify({
              color:'danger',
              text:this.$t('shareRemovalError')
            })
          }
        })
        .catch((error)   => { 
          console.log(error) 
          this.$vs.notify({
            color:'danger',
            text:this.$t('shareRemovalError')
          })
        })
    },
    // ROYBON / Récupère les usages du partage
    getShareUsage () {
      const params = `share_hash=${this.shareInfos.hash}`
      const rimnat_api_call_url = `${this.api_server_baseurl}/share/getShareUsage?${params}`
      axios.get(rimnat_api_call_url, {
      })
        .then((response) => { 
          if (response.data != null && response.data != 'null') {
            if (response.data != null && response.data != 'null') {
              this.shareUsage = response.data
              this.shareUsers = this.shareUsage.length
            }
          }
          this.shareUsersLoaded = true
        })
        .catch((error)   => { console.log(error) })
    },
    // ROYBON / Capitalize first letter
    capitalize (value) {
      if (!value) return ''
      value = value.toString()
      const arr = value.split(' ')
      const capitalized_array = []
      arr.forEach((word) => {
        const capitalized = word.charAt(0).toUpperCase() + word.slice(1)
        capitalized_array.push(capitalized)
      })
      return capitalized_array.join(' ')
    },
    // ROYBON / Date formatter
    shortDate (value) {
      return moment(String(value)).format('DD/MM/YY')
    }
  },
  computed: {
    shareLimitPasswordActive () {
      return (this.shareInfos.password !== null && this.shareInfos.password !== '' && this.shareInfos.password !== 'NULL')
    },
    shareLimitUsageNumberActive () {
      return (this.shareInfos.use_limit !== null && this.shareInfos.use_limit !== '' && this.shareInfos.use_limit !== 0 && this.shareInfos.use_limit !== 'NULL')
    },
    shareExpireDateActive () {
      return (this.shareInfos.expire_date !== null && this.shareInfos.expire_date !== '' && this.shareInfos.expire_date !== 'NULL')
    },
    shareLimitUsageExceeded () {
      if (this.shareLimitUsageNumberActive) return this.usesAvailable < 1
    },
    shareExpireDateExceeded () {
      if (this.shareExpireDateActive) return moment().isAfter(this.shareInfos.expire_date)
    },
    shareIsValid () {
      return !this.shareLimitUsageExceeded && !this.shareExpireDateExceeded
    },
    usesAvailable () {
      return Number(parseInt(this.shareInfos.use_limit) - parseInt(this.shareInfos.uses))
    },
    shareAlreadyUsed () {
      return parseInt(this.shareInfos.uses) > 0
    },
    cardBackgroundColor () {
      return this.shareIsValid ? '' : ''
    },
    cardTitleColor () {
      return this.shareIsValid ? 'primary' : 'danger'
    },
    shareIcon () {
      return this.shareIsValid ? 'CheckIcon' : 'XIcon'
    }
    
  },

  mounted () {
    if (Object.entries(this.data).length !== 0) {
      const { user_id  }          = JSON.parse(JSON.stringify(this.data))
      const { first_name  }       = JSON.parse(JSON.stringify(this.data))
      const { last_name  }        = JSON.parse(JSON.stringify(this.data))
      const { portal_id  }        = JSON.parse(JSON.stringify(this.data))
      const { sql_timestamp  }    = JSON.parse(JSON.stringify(this.data))
      const { hash }              = JSON.parse(JSON.stringify(this.data))
      const { expire_date  }      = JSON.parse(JSON.stringify(this.data))
      const { caps }              = JSON.parse(JSON.stringify(this.data))
      const { password }          = JSON.parse(JSON.stringify(this.data))
      const { use_limit }         = JSON.parse(JSON.stringify(this.data))
      const { uses }              = JSON.parse(JSON.stringify(this.data))

      this.shareUser.user_id          = user_id
      this.shareUser.first_name       = first_name
      this.shareUser.last_name        = last_name

      this.shareInfos.sql_timestamp    = sql_timestamp
      this.shareInfos.portal_id        = portal_id
      this.shareInfos.hash             = hash 
      this.shareInfos.expire_date      = expire_date
      this.shareInfos.password         = password
      this.shareInfos.use_limit        = use_limit
      this.shareInfos.uses             = uses
      this.shareInfos.caps             = JSON.parse(decodeURIComponent(caps))

      this.shareLoaded = true
    }
  },

  created () {
  },


  watch: {
    shareLoaded: function () {
      this.getShareUsage()
    }
    // shareUsage: function () {
    //   if (Object.entries(this.shareUsage).length !== 0) {
    //     this.shareUsers = this.shareUsage.length
    //   } else {
    //     this.shareUsers = 0
    //   }
    //   this.shareUsersLoaded = true
    // }
  }
}
</script>
<style lang="scss" scoped>
  .project_title {
    display: inline-table;
  }
</style>
